import React, { useContext, useEffect, useState } from "react";
import { useOrdersForStay, usePayers } from "../api/caseManagementApi";
import LoadingIndicator from "../common/LoadingIndicator";
import { PatientStayContext } from "../PatientPage";
import { Accordion } from "../common/Accordion";
import { sortBy } from "lodash";
import { ServiceItem } from "../common/ServiceItem";
import moment from "moment";
import { ServiceSummaryHistory } from "./ServiceSummaryHistory";

export const DAYS_UNTIL_ARCHIVED = 14;

export const StayServiceSummary = ({ payer, insurancePlans, isLoading }) => {
  const { stay, services, patientPayers } = useContext(PatientStayContext);

  const [payers, setPayers] = useState(undefined);

  const payersQuery = usePayers(patientPayers);
  const ordersQuery = useOrdersForStay(stay.id);

  const payersFetched = payersQuery.every((x) => x.isSuccess);

  useEffect(() => {
    if (payersFetched && !payers) {
      const data = payersQuery.flatMap((x) => x.data);
      setPayers(data);
    }
  }, [payersFetched, payersQuery, payers]);

  if (!Object.hasOwn(stay, "payerId")) {
    return <div> No payer information to show.</div>;
  }

  if (isLoading || services === undefined || ordersQuery.isLoading || payersQuery.some((x) => x.isLoading)) {
    return <LoadingIndicator />;
  }

  let serviceSummaries = {};
  if (payer && payers && insurancePlans) {
    serviceSummaries = getServiceSummaries(
      payer.id,
      payers,
      insurancePlans,
      ordersQuery.data,
      services.diagnoses,
      services.notes
    );
  }

  const { current, old } = serviceSummaries;

  if (current) {
    const accordionItems = getServiceSummaryItems(current.plans);
    const recentItems = getRecentItems(current.plans);

    return (
      <>
        <h3 className="m-r-sm">Active Payer - {payer && payer.displayName}</h3>
        {recentItems}
        {accordionItems}
        {old.length > 0 && <ServiceSummaryHistory plans={old} />}
      </>
    );
  }

  return <div> No payer information to show.</div>;
};

const getServiceSummaries = (currentPayerId, payers, insurancePlans, orders = [], diagnoses = [], notes = []) => {
  const serviceSummaries = { old: [] };

  payers.forEach((p) => {
    const payerPlans = Object.values(insurancePlans.filter((x) => x.payerId === p.id) || {}).filter(
      (plan) => plan.rank
    );
    let plans = [];

    if (payerPlans.length) {
      const ordersByMcce = getOrdersByMcce(orders);
      const diagnosesByMcce = getDiagnosesByMcce(diagnoses);
      const notesByMcce = getNotesByMcce(notes);

      plans = payerPlans.map((plan) => ({
        ...plan,
        medicallyComplexCareEvents: plan.medicallyComplexCareEvents.map((mcce) => ({
          ...mcce,
          orders: (ordersByMcce[mcce.id] || []).sort(sortByDateDesc),
          diagnoses: (diagnosesByMcce[mcce.description] || []).sort(sortByDateDesc),
          notes: (notesByMcce[mcce.description] || []).sort(sortByDateDesc),
        })),
      }));
    }
    if (p.id === currentPayerId) {
      serviceSummaries.current = { payer: p, plans, carveOuts: [] };
    } else {
      serviceSummaries.old.push({ payer: p, plans, carveOuts: [] });
    }
  });

  return serviceSummaries;
};

const getOrdersByMcce = (orders) => {
  return orders.reduce((acc, order) => {
    order.mcceIds.forEach((mcceId) => {
      acc[mcceId] = [...(acc[mcceId] || []), order];
    });
    return acc;
  }, {});
};

const getDiagnosesByMcce = (diagnoses) => {
  return diagnoses.reduce((acc, diag) => {
    diag.mcceDescriptions.forEach((mcceDesc) => {
      acc[mcceDesc] = [...(acc[mcceDesc] || []), diag];
    });
    return acc;
  }, {});
};

const getNotesByMcce = (notes) => {
  return notes.reduce((acc, note) => {
    note.mcceDescriptions.forEach((mcceDesc) => {
      acc[mcceDesc] = [...(acc[mcceDesc] || []), note];
    });
    return acc;
  }, {});
};

const sortByDateDesc = (a, b) => (a.date < b.date ? 1 : a.date > b.date ? -1 : 0);

const getHighlightColor = (mergedItems) => {
  for (let o of mergedItems) {
    if (moment().diff(moment(o.date), "days") <= DAYS_UNTIL_ARCHIVED) {
      return "#7AECEB";
    }
  }
  return "none";
};

const getRecentItems = (plans) => {
  if (!plans.length) {
    return null;
  }

  let recentEventCount = 0;

  const sortedPlans = sortBy(plans, ["rank"]);
  const planComponents = sortedPlans.map((plan) => {
    const recentEvents = plan.medicallyComplexCareEvents
      .flatMap((event) => [...event.orders, ...event.diagnoses])
      .filter((x) => moment().diff(moment(x.date), "days") <= DAYS_UNTIL_ARCHIVED);

    if (!recentEvents.length) {
      return null;
    }

    recentEventCount += recentEvents.length;

    return (
      <Accordion
        title={`${plan.careLevel} - (${recentEvents.length})`}
        description={plan.description}
        expandable={plan.medicallyComplexCareEvents.length}
        expanded
        highlight="#7AECEB"
        endContent={
          <button type="button" className="btn-link">
            Services Delivered <i className="fa fa-plus-square" />
          </button>
        }
      >
        {plan.medicallyComplexCareEvents.map((mcce) => (
          <ServiceItem key={`mcce-${mcce.id}-${mcce.description}`} {...mcce} hideEmpty expanded />
        ))}
      </Accordion>
    );
  });

  if (!recentEventCount) {
    return null;
  }

  return (
    <Accordion title={`New Documentation within the last 14 days (${recentEventCount})`} highlight="#7AECEB" expandable>
      {planComponents}
    </Accordion>
  );
};

const getServiceSummaryItems = (plans) => {
  if (plans.length === 0) {
    return <div> No payer information to show.</div>;
  }

  return sortBy(plans, [(plan) => plan.rank]).map((x) => {
    const events = x.medicallyComplexCareEvents.flatMap((event) => [...event.orders, ...event.diagnoses]);
    const title = events.length > 0 ? `${x.careLevel} - (${events.length})` : x.careLevel;

    return (
      <Accordion
        title={title}
        description={x.description}
        expandable={x.medicallyComplexCareEvents.length}
        highlight={getHighlightColor(events)}
        endContent={
          events.length > 0 && (
            <button type="button" className="btn-link">
              Services Delivered <i className="fa fa-plus-square" />
            </button>
          )
        }
      >
        {x.medicallyComplexCareEvents.map((mcce) => (
          <ServiceItem key={`mcce-${mcce.id}-${mcce.description}`} {...mcce} />
        ))}
      </Accordion>
    );
  });
};
