import React, { useState } from "react";

export const Accordion = ({ title, description, expandable, highlight, endContent, expanded = false, children }) => {
  const [open, setOpen] = useState(expanded);

  const toggle = () => {
    if (expandable) {
      setOpen((prevOpen) => !prevOpen);
    }
  };

  return (
    <>
      <div className="panel panel-default m-b-xxs">
        <div
          className={`panel-heading d-flex flex-row justify-between ${expandable ? "clickable" : ""}`}
          style={{ backgroundColor: expandable && highlight ? highlight : "none" }}
          onClick={toggle}
        >
          <div className="d-flex flex-column">
            <span className="flex-1-auto" style={{ fontWeight: "bold" }}>
              {title}
            </span>
            {description && (
              <span className="flex-1-auto" style={{ fontSize: "11px" }}>
                {description}
              </span>
            )}
          </div>
          <div className="d-flex">{endContent}</div>
        </div>
        {open && <div className="panel-body">{children}</div>}
      </div>
    </>
  );
};
