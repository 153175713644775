import React, { useState } from "react";
import { ServiceItem } from "../common/ServiceItem";

export const ServiceSummaryHistory = ({ plans }) => {
  const [historyOpen, setHistoryOpen] = useState(false);

  const historicEvents = getHistoricEvents(plans);
  const events = historicEvents.flatMap((event) => [...event.orders, ...event.diagnoses]);

  return (
    <>
      <div
        className="d-flex align-center clickable"
        onClick={() => {
          setHistoryOpen((prev) => !prev);
        }}
      >
        <i className={`fa fa-${historyOpen ? "minus" : "plus"}-square fa-lg m-r-xs`} />
        <h3>{`History (${events.length})`}</h3>
      </div>
      {historyOpen &&
        historicEvents.map((mcce) => (
          <ServiceItem key={`mcce-${mcce.id}-${mcce.description}`} {...mcce} collapseOldItems={false} />
        ))}
    </>
  );
};

const getHistoricEvents = (plans) => {
  const historicPlans = plans
    .flatMap((item) => item.plans)
    .filter(
      (plan) =>
        plan.medicallyComplexCareEvents.length > 0 &&
        plan.medicallyComplexCareEvents.some((event) => event.orders.length > 0 || event.diagnoses.length > 0)
    );

  return historicPlans.flatMap((plan) =>
    plan.medicallyComplexCareEvents.filter((event) => event.orders.length > 0 || event.diagnoses.length > 0)
  );
};
