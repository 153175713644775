import React, { useState } from "react";
import { sortBy } from "lodash";
import moment from "moment";

import Modal from "./Modal";
import { ServiceItem } from "./ServiceItem";

export const ServiceItemList = ({ items }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notesToView, setNotesToView] = useState([]);

  const onModalOpen = (notes) => {
    setNotesToView(notes);
    setIsModalOpen(true);
  };

  const onModalClose = () => {
    setNotesToView([]);
    setIsModalOpen(false);
  };

  return (
    <>
      <ul>
        {Object.keys(items).map((date) => (
          <li className="m-b-sm" key={date}>
            <b>{moment(date).format("M/DD/YYYY")}</b>
            <ul>
              {items[date]["diagnoses"].map((d) => {
                return (
                  <li className="m-b-sm" key={d.id}>
                    Patient presents with a diagnosis of {d.description}
                  </li>
                );
              })}
              {items[date]["orders"].map((o) => {
                return (
                  <li className="m-b-sm" key={o.id}>
                    {o.description}
                  </li>
                );
              })}
              {items[date]["notes"] && items[date]["notes"].length > 0 && (
                <li className="m-b-sm">
                  <span
                    className="clickable"
                    onClick={() => onModalOpen(items[date]["notes"])}
                    style={{ color: "#337ab7", textDecoration: "underline" }}
                  >
                    View {items[date]["notes"].length} Related Progress Notes
                  </span>
                </li>
              )}
            </ul>
          </li>
        ))}
      </ul>
      <Modal open={isModalOpen} onClose={onModalClose} title="Progress Notes">
        <div>
          {notesToView.map((n) => {
            return (
              <div key={n.id}>
                <p>{n.note}</p>
              </div>
            );
          })}
        </div>
      </Modal>
    </>
  );
};

export default function ServiceSummary({ plans = [], carveOuts = [] }) {
  return (
    <div>
      {plans.length <= 0 && carveOuts.length <= 0 && <p>No services are expected at this time.</p>}
      <div className="row">
        {plans.length > 0 && (
          <div className={carveOuts.length > 0 ? "col-sm-7 col-md-6" : "col-xs-12"}>
            {sortBy(plans, [(plan) => plan.rank]).map((plan) => {
              return (
                <React.Fragment key={plan.id}>
                  <h4>{plan.careLevel}</h4>
                  <p>{plan.description}</p>
                  {plan.medicallyComplexCareEvents.map((mcce) => (
                    <ServiceItem key={`mcce-${mcce.id}-${mcce.description}`} {...mcce} />
                  ))}
                </React.Fragment>
              );
            })}
          </div>
        )}
        {carveOuts.length > 0 && (
          <div className={plans.length > 0 ? "col-sm-5 col-md-6" : "col-xs-12"}>
            <h4>Other Billable Items</h4>
            {carveOuts.map((co) => {
              return <ServiceItem key={`carveOut-${co.id}`} {...co} />;
            })}
          </div>
        )}
      </div>
    </div>
  );
}
