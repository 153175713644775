import { has } from "lodash";
import moment from "moment";
import React, { useState, useMemo } from "react";
import { ServiceItemList } from "./ServiceSummary";

export const DAYS_UNTIL_ARCHIVED = 14;
export const THIRTY_DAYS_AGO = moment().subtract(30, "days");

export const ServiceItem = ({
  id,
  description,
  orders = [],
  diagnoses = [],
  notes = [],
  collapseOldItems = true,
  hideEmpty = false,
  expanded = false,
}) => {
  const [open, setOpen] = useState(expanded);
  const [oldItemsOpen, setOldItemsOpen] = useState(false);

  const mergedItems = orders.concat(diagnoses).concat(notes);
  const mergedGroupedByDate = getItemsGroupedByDate(mergedItems);

  const expandable = useMemo(() => {
    return orders.length > 0 || diagnoses.length > 0 || notes.length > 0;
  }, [orders, diagnoses]);

  const hasOldItems = useMemo(() => {
    return expandable && Object.keys(mergedGroupedByDate.older).length > 0;
  });

  const toggle = () => {
    if (expandable) {
      setOpen((prevOpen) => !prevOpen);
    }
  };

  const oldItemsToggle = () => {
    if (hasOldItems) {
      setOldItemsOpen((x) => !x);
    }
  };

  const chooseColor = (mergedItems) => {
    for (let o of mergedItems) {
      if (moment().diff(moment(o.date), "days") <= DAYS_UNTIL_ARCHIVED) {
        return "#7AECEB";
      }
    }
    return "none";
  };

  if (hideEmpty && !expandable) {
    return null;
  }

  return (
    <>
      <div key={id} className="panel panel-default m-b-xxs">
        <div
          className={`panel-heading d-flex ${expandable ? "clickable" : ""}`}
          style={{
            backgroundColor: expandable ? chooseColor(mergedItems) : "none",
          }}
          onClick={toggle}
        >
          <span className="flex-1-auto">{description}</span>
          {expandable && (
            <button type="button" className="btn-link">
              Services Delivered <i className={`fa fa-${open ? "minus" : "plus"}-square`} />
            </button>
          )}
        </div>
        {expandable && open && (
          <div className="panel-body">
            {Object.keys(mergedGroupedByDate.recent).length > 0 && (
              <ServiceItemList items={mergedGroupedByDate.recent} />
            )}
            {hasOldItems &&
              (collapseOldItems ? (
                <div>
                  <button className="btn-link" onClick={oldItemsToggle}>
                    {oldItemsOpen ? "Hide" : "Show"} Old Items
                  </button>
                  {oldItemsOpen && <ServiceItemList items={mergedGroupedByDate.older} />}
                </div>
              ) : (
                <ServiceItemList items={mergedGroupedByDate.older} />
              ))}
          </div>
        )}
      </div>
    </>
  );
};
const getItemsGroupedByDate = (items) => {
  return items.reduce(
    (acc, item) => {
      const targetGroup = moment(item.date).isBefore(THIRTY_DAYS_AGO) ? "older" : "recent";

      if (!acc[targetGroup][item.date]) {
        acc[targetGroup][item.date] = { orders: [], diagnoses: [], notes: [] };
      }

      if (has(item, "code")) {
        acc[targetGroup][item.date]["diagnoses"].push(item);
      }

      if (has(item, "category")) {
        acc[targetGroup][item.date]["orders"].push(item);
      }

      if (has(item, "note")) {
        acc[targetGroup][item.date]["notes"].push(item);
      }

      return acc;
    },
    {
      recent: {},
      older: {},
    }
  );
};
